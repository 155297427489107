import React from "react"
import styled from "styled-components"

const SplitSectionContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 60% 30%;
  column-gap: 1rem;
  justify-content: space-between;
  margin: 4rem auto;
  padding-top: 0 !important;
  border-bottom: 0 !important;
  img {
    height: auto;
    flex-grow: 2;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
      0 18px 36px -18px rgba(0, 0, 0, 0.3),
      0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  }
`
//@ts-ignore

const SplitSection = ({ children }) => (
  <SplitSectionContainer>{children}</SplitSectionContainer>
)

export default SplitSection
