// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-thoughts-calisthenics-building-muscle-tsx": () => import("./../../../src/pages/thoughts/calisthenics-building-muscle.tsx" /* webpackChunkName: "component---src-pages-thoughts-calisthenics-building-muscle-tsx" */),
  "component---src-pages-thoughts-calisthenics-in-2021-tsx": () => import("./../../../src/pages/thoughts/calisthenics-in-2021.tsx" /* webpackChunkName: "component---src-pages-thoughts-calisthenics-in-2021-tsx" */),
  "component---src-pages-thoughts-index-tsx": () => import("./../../../src/pages/thoughts/index.tsx" /* webpackChunkName: "component---src-pages-thoughts-index-tsx" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-exercise-landing-page-template-tsx": () => import("./../../../src/templates/exerciseLandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-exercise-landing-page-template-tsx" */),
  "component---src-templates-exercise-template-tsx": () => import("./../../../src/templates/exerciseTemplate.tsx" /* webpackChunkName: "component---src-templates-exercise-template-tsx" */)
}

