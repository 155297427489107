import styled from "styled-components"

export const Pros = styled.ul`
  h3 {
    color: green;
  }
  list-style: none;
`

export const Cons = styled.ul`
  h3 {
    color: red;
  }
  list-style: none;
  border: 1px solid black;
`
